import { deepClone, formatUrl } from '@monash/portal-frontend-common';
import { getTimestampFromDateAndTimeInputs } from '../../../utilities/get-timestamp-from-date-and-time-inputs';
import { NOTIFICATION_APPS } from '../../../../constants/notifications-props';
import { getTimeInputFromTimestamp } from '../../../utilities/get-time-input-from-timestamp';

export const transformNotificationDataForForm = (notification) => {
  if (!notification) return null;

  const newNotification = deepClone(notification);

  // add startTime field
  newNotification.startTime = getTimeInputFromTimestamp(
    newNotification.startDate
  );

  // transform startDate timestamp to date object
  newNotification.startDate = new Date(newNotification.startDate);

  // add endTime field
  newNotification.endTime = getTimeInputFromTimestamp(newNotification.endDate);

  // transform endDate timestamp to date object
  newNotification.endDate = new Date(newNotification.endDate);

  // remove unnecessary data fields
  delete newNotification.history;
  delete newNotification.status;

  return newNotification;
};

export const transformFormDataForSubmission = (
  typeId,
  formData,
  startNow,
  endNow
) => {
  const submissionData = deepClone(formData);

  // create startDate timestamp
  submissionData.startDate = startNow
    ? null
    : getTimestampFromDateAndTimeInputs(
        submissionData.startDate,
        submissionData.startTime
      );

  // create endDate timestamp
  submissionData.endDate = endNow
    ? Date.now()
    : getTimestampFromDateAndTimeInputs(
        submissionData.endDate,
        submissionData.endTime
      );

  // remove startTime and endTime (form fields)
  delete submissionData.startTime;
  delete submissionData.endTime;

  // clean up user group (empty selections)
  submissionData.studentUserGroups = submissionData.studentUserGroups.filter(
    (v) => v
  );
  submissionData.staffUserGroups = submissionData.staffUserGroups.filter(
    (v) => v
  );

  // clean up links (format URLs and remove empty links)
  submissionData.links = submissionData.links.reduce((acc, curr) => {
    if (!curr.label && !curr.url) {
      return acc;
    } else {
      return [
        ...acc,
        {
          label: curr.label,
          url: formatUrl(curr.url),
        },
      ];
    }
  }, []);

  // add typeId
  submissionData.type = typeId;

  return submissionData;
};

export const checkHasUnavailableUserGroups = (notification, allUserGroups) => {
  const apps = Object.values(NOTIFICATION_APPS);

  // for each app, check if there is any unavailable user groups
  const hasUnavailableUserGroups = notification?.apps.some((appId) => {
    const app = apps.find((app) => app.id === appId);
    const selectedUserGroups = notification[app.userGroupsKey];
    const selectedUnavailableUserGroups = allUserGroups[appId].filter(
      (group) => selectedUserGroups.includes(group.code) && !group.available
    );

    return selectedUnavailableUserGroups.length !== 0;
  });

  return hasUnavailableUserGroups;
};

export const getErrorFromApiErrorMsg = (
  apiErrorMsg,
  // generic error message
  serverErrorMsg,
  clientErrorMsg
) => {
  switch (Array.from(apiErrorMsg)[0]) {
    case '4': {
      return clientErrorMsg || serverErrorMsg;
    }
    case '5': {
      return serverErrorMsg;
    }
    default: {
      return serverErrorMsg;
    }
  }
};
