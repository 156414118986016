import { DatePicker, TimePicker } from '@monash/portal-react';
import React, { useEffect } from 'react';
import c from '../notification-form.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import InlineErrorMessage from '../../../../common/inline-error-message/InlineErrorMessage';
import { getTimestampFromDateAndTimeInputs } from '../../../../utilities/get-timestamp-from-date-and-time-inputs';

const NotificationEndDateAndTime = ({ disabled, required = false }) => {
  const {
    watch,
    trigger,
    control,
    formState: { errors, touchedFields },
  } = useFormContext();

  const startDate = watch('startDate');
  const startTime = watch('startTime');
  const endDate = watch('endDate');
  const endTime = watch('endTime');

  const validateEndDate = (value) => {
    if (!value && required) return 'A date must be selected';

    if (endTime) {
      const endDateTimeStamp = getTimestampFromDateAndTimeInputs(
        value,
        endTime
      );
      if (endDateTimeStamp < Date.now())
        return 'The end date cannot be before the current date';

      const startDateTimeStamp = getTimestampFromDateAndTimeInputs(
        startDate,
        startTime
      );
      if (endDateTimeStamp < startDateTimeStamp)
        return 'The end date and time must be after the start date and time';
    }
  };

  const getErrorMessage = () => {
    if (!endDate && !endTime) {
      return 'A day and a time must be selected';
    } else if (errors.endDate) {
      return errors.endDate?.message;
    } else if (errors.endTime) {
      return 'A time must be selected';
    }
  };

  useEffect(() => {
    // select day is set when textField onBlur fires hence validation must be triggered as an effect
    if (touchedFields.endDate) trigger('endDate');
  }, [endDate, endTime]);

  return (
    <div className={c.inputGroup}>
      <label>End date and time</label>
      <div className={c.dateAndTime}>
        <Controller
          control={control}
          name="endDate"
          rules={{
            validate: !disabled && validateEndDate,
          }}
          render={({ field: { onBlur, onChange, value } }) => (
            <DatePicker
              disablePast={true}
              dateFormat="dd/MM/yyyy"
              selectedDay={value}
              setSelectedDay={onChange}
              onBlur={onBlur}
              disabled={disabled}
            />
          )}
        />
        <Controller
          control={control}
          name="endTime"
          rules={{
            required,
          }}
          render={({ field: { onBlur, onChange, value } }) => (
            <TimePicker
              time={value}
              onChange={onChange}
              onBlur={onBlur}
              disabled={disabled}
            />
          )}
        />
      </div>
      {(errors.endDate || errors.endTime) && (
        <InlineErrorMessage message={getErrorMessage()} />
      )}
    </div>
  );
};

export default NotificationEndDateAndTime;
