import { getTimestampFromDateAndTimeInputs } from '../../utilities/get-timestamp-from-date-and-time-inputs';

export const getErrorMessage = ({ publishDate, publishTime }) => {
  const publishTimestamp = getTimestampFromDateAndTimeInputs(
    publishDate,
    publishTime
  );

  if (!publishDate && !publishTime) {
    return 'A date and time must be selected';
  }

  if (!publishDate) {
    return 'Please add a date';
  }

  if (!publishTime) {
    return 'Please add a time';
  }

  if (publishTimestamp && publishTimestamp < Date.now()) {
    return 'Date is in the past';
  }
};
