import { getTimestampFromDateAndTimeInputs } from '../../../../utilities/get-timestamp-from-date-and-time-inputs';

export const calculatePublishDate = ({
  publishDate,
  publishTime,
  isDraft,
  isScheduledPublish,
}) => {
  if (isDraft) {
    return null;
  }

  return isScheduledPublish
    ? getTimestampFromDateAndTimeInputs(publishDate, publishTime)
    : Date.now();
};
