import { formatUrl } from '@monash/portal-frontend-common';
import { getUserGroupFromCode } from '../../../../common/user-groups-selector/utils';
import { getTimestampFromDateAndTimeInputs } from '../../../../utilities/get-timestamp-from-date-and-time-inputs';
import { calculatePublishDate } from './calculate-publish-date';

export const transformFormDataForSubmission = (
  values,
  userGroupsWithSuffixes,
  isScheduledPublish,
  isDraft
) => {
  const {
    title,
    links,
    description,
    userGroups,
    startDate,
    startTime,
    endDate,
    endTime,
    publishDate,
    publishTime,
  } = values;

  const postData = {
    title,
    links: links
      .map((link) => ({ ...link, url: formatUrl(link.url) }))
      .filter((link) => link.label && link.url),
    description,
    startDate: getTimestampFromDateAndTimeInputs(startDate, startTime),
    endDate: getTimestampFromDateAndTimeInputs(endDate, endTime),
    publishDate: calculatePublishDate({
      publishDate,
      publishTime,
      isDraft,
      isScheduledPublish,
    }),
    author: 'Monash University',
    userGroups: userGroups.map((code) =>
      getUserGroupFromCode(code, userGroupsWithSuffixes)
    ),
  };

  return postData;
};
