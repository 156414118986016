import React from 'react';
import c from './inline-error-message.module.scss';
import { Icon } from '@monash/portal-react';
import classNames from 'classnames';

const InlineErrorMessage = ({ message, className }) => {
  const classes = classNames(c.inlineErrorMessage, className);

  return (
    <div className={classes}>
      <Icon.Alert size={16} />
      <p>{message}</p>
    </div>
  );
};

export default InlineErrorMessage;
