import React from 'react';
import c from './history-timeline.module.scss';
import { format } from 'date-fns';

const HistoryTimeline = ({ history }) => {
  const reversedHistory = [...history].reverse();
  const formatDate = (date) => format(date, 'dd/MM/yyyy h:mmaaa');

  return (
    <div className={c.historyTimeline}>
      <div className={c.events}>
        {reversedHistory.map((event) => {
          const emailString = event.user.email ? ` (${event.user.email}) ` : '';

          return (
            <div className={c.event} key={`${event.type}-${event.time}`}>
              <h3>{event.type}</h3>
              <p>{`by ${event.user.displayName + emailString} at ${formatDate(
                event.time
              )}`}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HistoryTimeline;
