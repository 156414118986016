import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import {
  Accordion,
  Button,
  Icon,
  LoadingIndicator,
  RemovableTag,
  TextField,
  toggleExpandedIndexes,
} from '@monash/portal-react';
import SlideOutForm from '../../../common/slide-out/SlideOutForm';
import PreviewUpdateCard from '../update-card/PreviewUpdateCard';
import { SlideOutContext } from '../../../common/slide-out/SlideOutWrapper';
import NotificationLink from '../../../admin/notifications/notification-form/notification-link/NotificationLink';
import { NOTIFICATION_EMPTY_LINK } from '../../../../constants/notifications-props';
import UserGroupsSelector from '../../../common/user-groups-selector/UserGroupsSelector';
import {
  modifyCodesForCitizenship,
  removeCodeSuffix,
} from '../../../common/user-groups-selector/utils';
import DateAndTime from '../../../common/date-and-time/DateAndTime';
import {
  getErroredAccordionIndexes,
  validateStartAndEndDateOnSubmit,
} from './utils';
import InlineErrorMessage from '../../../common/inline-error-message/InlineErrorMessage';
import SchedulePublishModal from '../../../common/schedule-publish-modal/SchedulePublishModal';
import { APIContext, AuthContext } from '@monash/portal-frontend-common';
import ACCESS_ROLES from '../../../../constants/user-roles';
import { isAuthorized } from '../../../utilities/is-authorized';
import { transformFormDataForSubmission } from './utils/transform-form-data-for-submission';
import { transformUpdateDataForForm } from './utils/transform-update-data-for-form';
import { UPDATE_STATUSES } from '../constants';
import HistoryTimeline from '../../../common/history-timeline/HistoryTimeline';
import c from './update-form.module.scss';

const UpdateForm = ({
  existingUpdate,
  userGroups,
  setUpdatesList,
  getUpdates,
}) => {
  const [openAccordionIndexes, setOpenAccordionIndexes] = useState([]);
  const { halfWidth, setOpen, open } = useContext(SlideOutContext);
  const authCtx = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [showOrGroupModal, setShowOrGroupModal] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);

  const { createUpdate, editUpdate, deleteUpdate } = useContext(APIContext);

  const initialValues = transformUpdateDataForForm(existingUpdate);

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
  });

  const isExisting = Boolean(existingUpdate);
  const isActive = existingUpdate?.status === UPDATE_STATUSES.ACTIVE;

  const availableUserGroups = userGroups.filter((group) => group.available);
  const userGroupsWithSuffixes = modifyCodesForCitizenship(userGroups);

  const {
    control,
    watch,
    trigger,
    register,
    getValues,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, isValid, isDirty, touchedFields, isSubmitted },
  } = methods;

  register('userGroups', {
    validate: (value) =>
      (Array.isArray(value) && value.length !== 0) ||
      'Add at least one user group',
  });

  const { append: appendLink, remove: removeLink } = useFieldArray({
    name: 'links',
    control,
  });

  const title = watch('title');
  const description = watch('description');
  const links = watch('links');
  const orGroups = watch('userGroups');
  const publishDate = watch('publishDate');
  const publishTime = watch('publishTime');
  const startDate = watch('startDate');
  const startTime = watch('startTime');
  const endDate = watch('endDate');
  const endTime = watch('endTime');

  const onDeleteUpdate = async () => {
    try {
      const response = await deleteUpdate(existingUpdate.id);
      const newUpdates = await getUpdates();
      setUpdatesList(newUpdates);
      setOpen(false);
      return response;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const formHeaderActions = [
    {
      icon: Icon.ClockReverse,
      onClick: () => {},
      label: 'View history',
      disabled: !existingUpdate,
      modalProps: {
        title: 'History',
        content: <HistoryTimeline history={existingUpdate?.history} />,
      },
    },
    isActive
      ? {
          icon: Icon.Box,
          label: 'Archive update',
          disabled: !isExisting,
          modalProps: {
            title: 'Archive update',
            content:
              'Archive will remove the update from student app. Are you sure you want to archive this update?',
            cta: {
              async: true,
              onClick: onDeleteUpdate,
              variant: 'delete',
              label: 'Archive',
            },
          },
        }
      : {
          icon: Icon.Trash,
          label: 'Delete update',
          disabled: !isExisting,
          modalProps: {
            title: 'Delete update',
            content: 'Are you sure you want to delete this update?',
            cta: {
              async: true,
              onClick: onDeleteUpdate,
              variant: 'delete',
              label: 'Delete',
            },
          },
        },
  ];

  const validateActionablePeriod = () => {
    return validateStartAndEndDateOnSubmit({
      startDate,
      startTime,
      endDate,
      endTime,
    });
  };

  const removeUserGroup = (code) => {
    const newSelectedUserGroups = orGroups.filter((group) => group !== code);
    setValue('userGroups', newSelectedUserGroups, {
      shouldValidate: true,
    });
  };

  const validateForm = async ({ isDraft } = {}) => {
    if (isDraft) {
      return { isValid: true };
    }

    if (!isValid) {
      await trigger();
      const erroredAccordionIndexes = getErroredAccordionIndexes({
        errors,
      });
      setOpenAccordionIndexes(erroredAccordionIndexes);
    }

    return { isValid };
  };

  const openSchedulePublishModal = async () => {
    // This is needed to change the form to using reValidateMode
    handleSubmit(() => {})();

    const { isValid } = await validateForm();
    if (isValid) {
      setIsScheduleModalOpen(true);
    }
  };

  const onSubmit = async ({ isScheduledPublish, isDraft }) => {
    // This is needed to change the form to using reValidateMode
    handleSubmit(() => {})();

    const { isValid } = await validateForm({ isDraft });
    const values = getValues();

    if (isValid || isDraft) {
      try {
        setIsLoading(true);
        const body = transformFormDataForSubmission(
          values,
          userGroupsWithSuffixes,
          isScheduledPublish,
          isDraft
        );

        if (existingUpdate) {
          await editUpdate(existingUpdate.id, body);
        } else {
          await createUpdate(body);
        }

        const newUpdates = await getUpdates();
        setUpdatesList(newUpdates);

        setOpen(false);
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const { SUPPORT_DEVELOPER, UPDATES_ADMIN, UPDATES_DRAFT } = ACCESS_ROLES;

  const formSubmitActions = [
    {
      label: 'Publish',
      onClick: onSubmit,
      requiredRoles: [SUPPORT_DEVELOPER, UPDATES_ADMIN],
      showWhenActive: true,
    },
    {
      label: 'Save as draft',
      onClick: () => onSubmit({ isDraft: true }),
      requiredRoles: [SUPPORT_DEVELOPER, UPDATES_ADMIN, UPDATES_DRAFT],
    },
    {
      label: 'Schedule publish',
      onClick: openSchedulePublishModal,
      requiredRoles: [SUPPORT_DEVELOPER, UPDATES_ADMIN],
    },
  ]
    .filter((action) =>
      isAuthorized(authCtx?.user?.roles, action.requiredRoles)
    )
    .filter((action) => {
      const isAvailable = !isExisting || !isActive;
      return isAvailable || action.showWhenActive;
    });

  useEffect(() => {
    reset(initialValues);
    setOpenAccordionIndexes([]);
  }, [open, existingUpdate]);

  // Since endTime is the only field with the validate function
  // publishDate needs to be triggered manually
  useEffect(() => {
    if (isSubmitted) {
      trigger('endTime');
    }
  }, [startDate, startTime, endDate]);

  return (
    <FormProvider {...methods}>
      <SlideOutForm
        name="update"
        width={halfWidth}
        heading={title}
        headerActions={formHeaderActions}
        submitActions={formSubmitActions}
        isDirty={isDirty}
        status={existingUpdate?.status}
        publishDate={existingUpdate?.publishDate}
      >
        {isLoading ? (
          <div className={c.loadingIndicatorWrapper}>
            <LoadingIndicator />
          </div>
        ) : (
          <>
            <PreviewUpdateCard
              title={title}
              description={description}
              links={links}
            />

            <Accordion
              className={c.customAccordion}
              renderHiddenContent
              expandedIndexes={openAccordionIndexes}
              onClick={(index) => {
                setOpenAccordionIndexes(
                  toggleExpandedIndexes(index, openAccordionIndexes)
                );
              }}
              items={[
                {
                  title: 'Display',
                  content: (
                    <div className={c.updateForm}>
                      <div className={c.inputGroup}>
                        <label>Title</label>
                        <Controller
                          control={control}
                          name="title"
                          rules={{
                            required: 'Title cannot be empty',
                          }}
                          render={({ field: { onBlur, onChange, value } }) => (
                            <TextField
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              placeholder="Title"
                              error={() => errors.title}
                              errorMsg={errors.title?.message}
                            />
                          )}
                        />
                      </div>

                      <div className={c.inputGroup}>
                        <label>Description</label>
                        <Controller
                          control={control}
                          name="description"
                          rules={{
                            required: 'Description cannot be empty',
                          }}
                          render={({ field: { onBlur, onChange, value } }) => (
                            <TextField
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              placeholder="Description"
                              error={() => errors.description}
                              errorMsg={errors.description?.message}
                              multiline
                            />
                          )}
                        />
                      </div>

                      <div className={c.inputGroup}>
                        <label>Link (Optional)</label>
                        <div className={c.links}>
                          {links.length !== 0 &&
                            links.map((link, i) => (
                              <NotificationLink
                                key={link.id}
                                index={i}
                                removeLink={removeLink}
                              />
                            ))}
                        </div>

                        <Button
                          variant="text"
                          mode="card"
                          size="small"
                          onClick={() => appendLink(NOTIFICATION_EMPTY_LINK)}
                        >
                          Add link
                        </Button>
                      </div>
                    </div>
                  ),
                },
                {
                  title: 'Actionable period (optional)',
                  content: (
                    <div className={c.updateForm}>
                      <DateAndTime
                        label="Start date and time"
                        names={{ date: 'startDate', time: 'startTime' }}
                      />
                      <DateAndTime
                        label="End date and time"
                        names={{ date: 'endDate', time: 'endTime' }}
                        errorMessage={errors.endTime?.message}
                        validateEndTime={validateActionablePeriod}
                      />
                    </div>
                  ),
                },
                {
                  title: 'User group',
                  content: (
                    <div className={c.updateForm}>
                      <div className={c.tags}>
                        {orGroups.map((group) => {
                          const name = userGroupsWithSuffixes.find(
                            (orGroup) => group === orGroup.code
                          )?.name;

                          return (
                            <RemovableTag
                              key={group}
                              text={`${removeCodeSuffix(group)} - ${name}`}
                              size="large"
                              color="greyBlue"
                              onRemove={() => removeUserGroup(group)}
                            />
                          );
                        })}
                      </div>

                      <div className={c.inputGroup}>
                        <Button
                          variant="text"
                          mode="card"
                          size="small"
                          onClick={() => {
                            setShowOrGroupModal(true);
                          }}
                        >
                          Add user group
                        </Button>

                        {errors.userGroups && (
                          <InlineErrorMessage
                            message={errors.userGroups.message}
                          />
                        )}
                      </div>

                      <UserGroupsSelector
                        control={control}
                        showModal={showOrGroupModal}
                        setShowModal={setShowOrGroupModal}
                        setValue={setValue}
                        selectedUserGroups={orGroups}
                        availableUserGroups={availableUserGroups}
                        showCountryTabs={true}
                        name="userGroups"
                      />

                      <SchedulePublishModal
                        isOpen={isScheduleModalOpen}
                        setIsOpen={setIsScheduleModalOpen}
                        publishDate={publishDate}
                        publishTime={publishTime}
                        touchedFields={touchedFields}
                        onPublish={() => {
                          setIsScheduleModalOpen(false);
                          onSubmit({ isScheduledPublish: true });
                        }}
                        trigger={trigger}
                        errors={errors}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </>
        )}
      </SlideOutForm>
    </FormProvider>
  );
};

export default UpdateForm;
