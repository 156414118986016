import { Icon, RouterState } from '@monash/portal-react';
import React, { useContext } from 'react';
import c from './applications.module.scss';
import TileButton from '../../common/tile-button/TileButton';
import ACCESS_ROLES from '../../../constants/user-roles';
import { AuthContext } from '@monash/portal-frontend-common';

const Applications = () => {
  const { redirect } = useContext(RouterState);
  const authCtx = useContext(AuthContext);
  const userRoles = authCtx?.user?.roles;
  const { ADMIN, UPDATES_ADMIN, UPDATES_DRAFT } = ACCESS_ROLES;

  const allApps = [
    {
      name: 'Home',
      link: '/home/updates',
      desc: 'Portal home updates',
      icon: <Icon.Home />,
      requiredRoles: [ADMIN, UPDATES_ADMIN, UPDATES_DRAFT],
    },
    {
      name: 'Links',
      link: '/links/links',
      desc: 'Links CRUD',
      icon: <Icon.Link />,
    },
  ];

  return (
    <div className={c.container}>
      <div className={c.applications}>
        <h1>Available Applications</h1>
        <p>Monash applications content management</p>
        <div className={c.appList}>
          {allApps
            .filter(
              (app) =>
                !app.requiredRoles ||
                app.requiredRoles.some((requiredRole) =>
                  userRoles?.includes(requiredRole)
                )
            )
            .map((app) => (
              <TileButton
                key={app.name}
                onClick={() => redirect(`apps${app.link}`)}
                icon={app.icon}
                title={app.name}
                description={app.desc}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Applications;
