import { Button, Modal, ModalSection } from '@monash/portal-react';
import DateAndTime from '../date-and-time/DateAndTime';
import c from './schedule-publish-modal.module.scss';
import InlineErrorMessage from '../inline-error-message/InlineErrorMessage';
import { useEffect } from 'react';
import { getErrorMessage } from './get-error-message';

const SchedulePublishModal = ({
  isOpen,
  setIsOpen,
  onPublish: onPublishProp,
  publishDate,
  publishTime,
  touchedFields,
  errors,
  trigger,
}) => {
  // Since publishTime is the field with the validate function
  // publishDate needs to be triggered manually
  useEffect(() => {
    if (touchedFields.publishDate) trigger('publishTime');
  }, [publishDate]);

  const onPublish = async () => {
    const hasErrors = Boolean(Object.keys(errors).length);

    if (!hasErrors) {
      onPublishProp();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={async () => {
        setIsOpen(false);
        await trigger();
      }}
    >
      <ModalSection title="Schedule publish update">
        <DateAndTime
          label="Select date and time"
          names={{ date: 'publishDate', time: 'publishTime' }}
          validateEndTime={() => {
            if (isOpen) {
              const err = getErrorMessage({
                publishDate,
                publishTime,
              });

              return err;
            }
          }}
        />

        {errors.publishTime && (
          <InlineErrorMessage
            className={c.error}
            message={errors.publishTime.message}
          />
        )}
      </ModalSection>

      <div className={c.actionsContainer}>
        <Button
          className={c.cancel}
          onClick={() => setIsOpen(false)}
          size="small"
          variant="text"
        >
          Cancel
        </Button>

        <Button onClick={onPublish} size="large">
          Schedule publish
        </Button>
      </div>
    </Modal>
  );
};

export default SchedulePublishModal;
