export const UPDATE_STATUSES = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

export const STATUS_FILTERS = [
  {
    text: 'Not selected',
    value: null,
  },
  {
    text: 'Draft',
    value: UPDATE_STATUSES.DRAFT,
  },
  {
    text: 'Scheduled',
    value: UPDATE_STATUSES.SCHEDULED,
  },
  {
    text: 'Active',
    value: UPDATE_STATUSES.ACTIVE,
  },
];
