import React, { useState } from 'react';
import {
  Alert,
  Button,
  Modal,
  ModalAction,
  ModalSection,
  useMedia,
} from '@monash/portal-react';
import c from './action-modal.module.scss';

const ActionModal = ({
  isActionModalOpen,
  setIsActionModalOpen,
  actionModalProps,
  setActionModalProps,
}) => {
  const size = useMedia();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const ctaButtonSize = size === 'S' ? 'small' : 'medium';

  const resetAction = () => {
    setIsActionModalOpen(false);
    setActionModalProps(null);
  };

  const confirmAsyncAction = async () => {
    setLoading(true);
    try {
      const response = await actionModalProps?.cta?.onClick();
      if (response) {
        setLoading(false);
        resetAction();
      }
    } catch (error) {
      setLoading(false);
      setError(actionModalProps?.cta?.onError(error) || error);
    }
  };

  const confirmAction = () => {
    actionModalProps?.cta?.onClick();
    resetAction();
  };

  const onModalClose = () => {
    setError(null);
    setIsActionModalOpen(false);
  };

  return (
    <Modal
      open={isActionModalOpen}
      onClose={onModalClose}
      size={size}
      ariaLabel={actionModalProps?.title}
      stopPropagationOnDefaultKeyDown={true}
    >
      <ModalSection title={actionModalProps?.title} titleTabIndex={null}>
        <div className={c.content}>{actionModalProps?.content}</div>
      </ModalSection>
      {actionModalProps?.cta ? (
        <ModalAction position="center">
          <div className={c.modalAction}>
            {error && <Alert type="error">{error}</Alert>}
            <div className={c.modalButtons}>
              <Button
                variant="text"
                size={ctaButtonSize}
                onClick={onModalClose}
              >
                Cancel
              </Button>
              <Button
                variant={actionModalProps?.cta?.variant}
                size={ctaButtonSize}
                loading={loading}
                onClick={
                  actionModalProps?.cta?.async
                    ? confirmAsyncAction
                    : confirmAction
                }
              >
                {actionModalProps?.cta?.label}
              </Button>
            </div>
          </div>
        </ModalAction>
      ) : null}
    </Modal>
  );
};

export default ActionModal;
