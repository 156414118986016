import React, { useState, useContext } from 'react';
import { SearchBox, Button, Icon } from '@monash/portal-react';
import c from './impersonation.module.scss';
import { APIContext } from '@monash/portal-frontend-common';
import UserCard from './user-card/UserCard';

const UserSearch = ({ onSelect, setRecentSearches, recentSearches }) => {
  const { checkStudentImpersonation } = useContext(APIContext);
  const [searchType, setSearchType] = useState(null); // null, real, test
  const [results, setResults] = useState(null);
  const [target, setTarget] = useState('');
  const [searched, setSearched] = useState('');
  const [loading, setLoading] = useState(false);

  const searchRealUsers = (studentId) => {
    setSearched(studentId);
    setLoading(true);
    checkStudentImpersonation(studentId)
      .then((response) => {
        setLoading(false);
        setSearchType('real');
        const { name, studentId } = response;

        setRecentSearches([
          { name, studentId },
          ...recentSearches.filter((search) => search.studentId !== studentId),
        ]);

        if (response) {
          setResults([response]);
        } else {
          setResults([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const realUserSearchBar = (
    <div className={c.searchBar}>
      <SearchBox
        searchString={target}
        onInput={setTarget}
        onSearch={(studentId) => searchRealUsers(studentId)}
        placeholder="Search by student ID"
      />
      <Button onClick={() => searchRealUsers(target)} loading={loading}>
        Search
      </Button>
    </div>
  );

  return (
    <div className={c.searchContainer}>
      {!searchType ? (
        <div className={c.header}>
          <h2>Impersonate user</h2>
          <p>
            Impersonate a different user to handle support problems or to see
            how their UI looks.
          </p>
        </div>
      ) : (
        <Button
          icon={<Icon.ArrowLeft />}
          iconPosition="left"
          variant="text"
          size="small"
          onClick={() => {
            setResults(null);
            setSearchType(null);
          }}
        >
          Back
        </Button>
      )}
      {/* real users */}
      {(!searchType || searchType === 'real') && realUserSearchBar}

      {!!searchType && results && !loading && (
        <div>
          {results.length ? (
            results.map((user) => (
              <UserCard
                key={searched}
                data={{ ...user, studentId: searched }}
                onSelect={onSelect}
              />
            ))
          ) : (
            <span className={c.notFound}>"{searched}" doesn't exist</span>
          )}
        </div>
      )}
    </div>
  );
};

export default UserSearch;
