import React, { useState, useContext } from 'react';
import { SearchBox, Button } from '@monash/portal-react';
import c from './impersonation.module.scss';
import { APIContext } from '@monash/portal-frontend-common';

export const IdSearch = () => {
  const { getIdentity } = useContext(APIContext);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [foundId, setFoundId] = useState(null);

  const getId = async (id) => {
    setLoading(true);
    setFoundId(null);

    const isStudentId = !id.includes('-');
    const idType = isStudentId ? 'studentId' : 'monashObjectId';
    const identity = await getIdentity(id, idType);
    const foundId = isStudentId
      ? identity.monashObjectID
      : identity.callistaPersonID;

    setLoading(false);
    setFoundId(foundId);
  };

  return (
    <div className={c.searchContainer}>
      <div className={c.header}>
        <h2>ID Search</h2>
        <p>Find a studentId or monashObjectId by providing one or the other.</p>
      </div>

      <div className={c.searchBar}>
        <SearchBox
          placeholder="Search by student ID or Monash Object ID"
          searchString={searchValue}
          onInput={setSearchValue}
          onSearch={(x) => getId(x)}
        />
        <Button onClick={() => getId(searchValue)} loading={loading}>
          Search
        </Button>
      </div>

      {foundId && <div>{foundId}</div>}
    </div>
  );
};
