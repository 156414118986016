import { modifyCodesForCitizenship } from '../../common/user-groups-selector/utils';

export const bySearchTerm = (searchTerm) => (update) => {
  if (searchTerm) {
    return update.title.toLowerCase().includes(searchTerm.toLowerCase());
  }
  return true;
};

export const byOrGroup = (userGroup) => (update) => {
  const modified = modifyCodesForCitizenship(update.userGroups);

  if (userGroup) {
    return modified?.some((group) => {
      return group.code === userGroup;
    });
  }
  return true;
};

export const byStatus = (status) => (update) => {
  if (status) {
    return update.status === status;
  }
  return true;
};
