import ACCESS_ROLES from '../../constants/user-roles';

export const isAuthorized = (userRoles, requiredRoles) => {
  if (userRoles?.includes(ACCESS_ROLES.ADMIN)) {
    return true;
  }

  return requiredRoles.some((requiredRole) =>
    userRoles?.includes(requiredRole)
  );
};
