import React, { useState } from 'react';
import c from './notification-app.module.scss';
import { useFormContext } from 'react-hook-form';
import { Button, Switch, RemovableTag } from '@monash/portal-react';
import InlineErrorMessage from '../../../../../common/inline-error-message/InlineErrorMessage';
import UserGroupsSelector from '../../../../../common/user-groups-selector/UserGroupsSelector';

const NotificationApp = ({
  app,
  apps,
  disabled,
  allUserGroups,
  availableUserGroups,
}) => {
  const {
    setValue,
    watch,
    register,
    control,
    trigger,
    formState: { errors },
  } = useFormContext();
  const on = apps?.includes(app.id);
  const userGroups = watch(app.userGroupsKey);
  const [showOrGroupModal, setShowOrGroupModal] = useState(false);

  register(app.userGroupsKey, {
    validate: (value) =>
      !on ||
      (Array.isArray(value) && value.length !== 0) ||
      'Add at least one user group',
  });

  const removeUserGroup = (code) => {
    const newSelectedUserGroups = userGroups.filter((group) => group !== code);
    setValue(app.userGroupsKey, newSelectedUserGroups, {
      shouldValidate: true,
    });
  };

  const switchOn = () =>
    setValue('apps', [...apps, app.id], { shouldValidate: true });

  const switchOff = () => {
    // remove app from apps field
    setValue(
      'apps',
      [...apps].filter((appId) => appId !== app.id),
      { shouldValidate: true }
    );
    // clear selected user groups of the app
    setValue(app.userGroupsKey, [], { shouldValidate: true });
  };

  return (
    <div className={c.notificationApp}>
      <div className={c.appSwitch}>
        <Switch
          on={on}
          ariaLabel={app.label}
          onClick={on ? switchOff : switchOn}
          disabled={disabled}
        />
        <span>{app.label}</span>
      </div>
      {on && (
        <div className={c.userGroups}>
          <label>User groups</label>
          <div className={c.tags}>
            {userGroups.map((code) => {
              const name = allUserGroups?.find(
                (orGroup) => code === orGroup.code
              )?.name;
              return (
                <RemovableTag
                  key={code}
                  text={`${code} - ${name}`}
                  color="greyBlue"
                  size="large"
                  disabled={disabled}
                  onRemove={() => removeUserGroup(code)}
                />
              );
            })}
          </div>
          <Button
            variant="text"
            mode="card"
            size="small"
            onClick={() => setShowOrGroupModal(true)}
            disabled={disabled}
          >
            Add user group
          </Button>
          <UserGroupsSelector
            control={control}
            onClose={() => trigger(app.userGroupsKey)}
            showModal={showOrGroupModal}
            setShowModal={setShowOrGroupModal}
            availableUserGroups={availableUserGroups}
            name={app.userGroupsKey}
            setValue={setValue}
            selectedUserGroups={userGroups}
          />
          {errors[app.userGroupsKey] && (
            <InlineErrorMessage message={errors[app.userGroupsKey]?.message} />
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationApp;
