import UserCard from '../user-card/UserCard';
import c from './recent-searches.module.scss';

const RecentSearches = ({ recentSearches, onSelect }) => {
  return (
    <div className={c.recentSearches}>
      <h3>Recent searches</h3>

      {recentSearches.map(({ name, studentId }) => {
        return (
          <UserCard key={name} data={{ name, studentId }} onSelect={onSelect} />
        );
      })}
    </div>
  );
};

export default RecentSearches;
