import React from 'react';
import c from './notification-link.module.scss';
import { Icon, IconButton, TextField } from '@monash/portal-react';
import { useFormContext, Controller } from 'react-hook-form';

const NotificationLink = ({ index, removeLink, disabled }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const links = watch('links');
  const link = links && links[index];
  const error = errors?.links && errors?.links?.[index];

  return (
    <div className={c.notificationLink}>
      <Controller
        control={control}
        name={`links.${index}.label`}
        render={({ field: { onBlur, onChange, value } }) => (
          <TextField
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            placeholder="Link name"
            disabled={disabled}
          />
        )}
      />
      <Controller
        control={control}
        name={`links.${index}.url`}
        rules={{
          required: link?.label ? 'URL is required' : false,
        }}
        render={({ field: { onBlur, onChange, value } }) => (
          <TextField
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            placeholder="https://www.example.com/"
            type="url"
            error={() => error?.url}
            errorMsg={error?.url.message}
            disabled={disabled}
          />
        )}
      />
      <IconButton
        onClick={() => removeLink(index)}
        icon={Icon.Trash}
        color={!disabled && 'var(--card-text-color)'}
        size={24}
        aria-label="Delete link"
        disabled={disabled}
      />
    </div>
  );
};

export default NotificationLink;
