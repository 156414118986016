const ACCESS_ROLES = {
  ADMIN: 'studentportal-support-admin',
  UPDATES_ADMIN: 'studentportal-updates-admin',
  UPDATES_DRAFT: 'studentportal-updates-draft',
  SUPPORT_DEVELOPER: 'studentportal-support-developer',
  NOTIFICATIONS_CRITICAL: 'studentportal-critical-notifications-admin',
  NOTIFICATIONS_NON_CRITICAL: 'studentportal-noncritical-notifications-admin',
  IMPERSONATION: 'studentportal-support-impersonate',
};

export default ACCESS_ROLES;
