import React, { useContext } from 'react';
import { AuthContext, MenuContext } from '@monash/portal-frontend-common';
import {
  Icon,
  Route,
  Sidebar,
  SidebarContainer,
  SidebarItem,
  SidebarSection,
  SidebarItemSwitch,
} from '@monash/portal-react';
import Updates from './updates/Updates';
import c from './home.module.scss';
import ACCESS_ROLES from '../../constants/user-roles';
import SlideOutWrapper from '../common/slide-out/SlideOutWrapper';

const Home = ({ root }) => {
  const { menu, handleMenu, closeMenu, menuRef, size } =
    useContext(MenuContext);
  const authCtx = useContext(AuthContext);

  const hasUpdatesAccess = [
    ACCESS_ROLES.ADMIN,
    ACCESS_ROLES.UPDATES_ADMIN,
    ACCESS_ROLES.UPDATES_DRAFT,
    ACCESS_ROLES.SUPPORT_DEVELOPER,
  ].some((x) => authCtx?.user?.roles?.includes(x));

  return (
    <Route to={`${root}/updates`} exact>
      <SidebarContainer onLeft={true}>
        <Sidebar
          title="Admin"
          ifMenu={menu}
          toggleMenu={handleMenu}
          closeMenu={closeMenu}
          size={size}
          menuRef={menuRef}
        >
          <SidebarSection title="Managing for">
            <SidebarItemSwitch text="Home" linkTo="/" />
          </SidebarSection>
          <SidebarSection title="Content">
            {hasUpdatesAccess && (
              <SidebarItem
                linkTo="apps/home/updates"
                text="Updates"
                icon={<Icon.Home />}
                onMenu={closeMenu}
              />
            )}
          </SidebarSection>
        </Sidebar>

        <SlideOutWrapper>
          <div className={c.container}>
            <Updates />
          </div>
        </SlideOutWrapper>
      </SidebarContainer>
    </Route>
  );
};

export default Home;
