import { modifyCodesForCitizenship } from '../../../../common/user-groups-selector/utils';
import { getTimeInputFromTimestamp } from '../../../../utilities/get-time-input-from-timestamp';
import { UPDATES_EMPTY_VALUES } from '../constants';

export const transformUpdateDataForForm = (existingUpdate) => {
  return existingUpdate
    ? {
        ...existingUpdate,
        startTime: getTimeInputFromTimestamp(existingUpdate.startDate),
        endTime: getTimeInputFromTimestamp(existingUpdate.endDate),
        publishTime: getTimeInputFromTimestamp(existingUpdate.publishDate),
        userGroups: modifyCodesForCitizenship(existingUpdate.userGroups).map(
          (userGroup) => userGroup.code
        ),
      }
    : UPDATES_EMPTY_VALUES;
};
