export const UPDATES_EMPTY_VALUES = {
  title: '',
  description: '',
  links: [],
  startDate: null,
  startTime: '',
  endDate: null,
  endTime: '',
  publishDate: null,
  publishTime: null,
  userGroups: [],
};
