import { useState } from 'react';
import { Checkbox, Modal, ModalSection, Tabs } from '@monash/portal-react';
import { COUNTRIES_LIST } from '../../../constants/countries';
import c from './user-groups-selector.module.scss';
import { modifyCodesForCitizenship, removeCodeSuffix } from './utils';

const UserGroupsSelector = ({
  showModal,
  setShowModal,
  onClose,
  name,
  setValue,
  availableUserGroups,
  selectedUserGroups,
  showCountryTabs = false,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const countryTabs = COUNTRIES_LIST.map((countryName) => ({
    title: countryName,
    content: modifyCodesForCitizenship(
      availableUserGroups?.filter((group) =>
        group.filters?.includes(countryName)
      )
    ),
  })).filter((countryTab) => countryTab.content.length);

  const titles = countryTabs.map((countryTab) => countryTab.title);

  const userGroups = showCountryTabs
    ? countryTabs[selectedTabIndex].content
    : availableUserGroups;

  const toggleUserGroup = (code) => {
    const newSelectedUserGroups = [...selectedUserGroups];
    const index = newSelectedUserGroups.findIndex((item) => item === code);
    if (index !== -1) {
      // remove user group
      newSelectedUserGroups.splice(index, 1);
    } else {
      // add user group
      newSelectedUserGroups.push(code);
    }
    setValue(name, newSelectedUserGroups, { shouldValidate: true });
  };

  return (
    <Modal
      open={showModal}
      onClose={() => {
        onClose?.();
        setShowModal(false);
      }}
    >
      <ModalSection title="User groups">
        {showCountryTabs && countryTabs.length > 1 && (
          <div className={c.tabsWrapper}>
            <Tabs
              tabs={titles}
              selected={selectedTabIndex}
              onChange={(i) => {
                setSelectedTabIndex(i);
              }}
            />
          </div>
        )}

        {userGroups.map((group) => {
          const text = `${removeCodeSuffix(group.code)} - ${group.name}`;
          return (
            <Checkbox
              key={group.name}
              text={text}
              onChange={() => {
                toggleUserGroup(group.code);
              }}
              on={selectedUserGroups?.find((item) => item === group.code)}
            />
          );
        })}
      </ModalSection>
    </Modal>
  );
};

export default UserGroupsSelector;
