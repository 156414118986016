import {
  Button,
  TextField,
  DropdownWithValue,
  Icon,
} from '@monash/portal-react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import MultiSelect from '../../common/multi-select/MultiSelect';
import c from './link-form.module.scss';
import { categories } from '@monash/portal-frontend-common';
import SlideOutForm from '../../common/slide-out/SlideOutForm';
import { SlideOutContext } from '../../common/slide-out/SlideOutWrapper';

const apps = [
  'WES',
  'Library',
  'Allocate+',
  'Google drive',
  'Moodle',
  'Email',
  'ESS',
  'Monash.edu',
  'Monash college',
  'Bluera',
  'Studentplacements',
  'Career gateway',
  'Intranet',
  'Forms',
  'vPermit',
  'Monashuniversity',
  'Career centre',
  'Locate-a-lab',
  'My.monash',
  'Finhelpdesk',
  'servicedeskonline',
  'Identity',
  'Scout',
  'Connect',
  'Chemwatch',
  'Riskcloud',
  'Amigo',
  'idmobile',
  'Handbook',
  'Google calendar',
  'Move',
  'LabArchives',
  'REX',
  'uniSuper',
  'Replicon',
  'myDevelopment',
  'myPlan',
  'myResearch',
  'mySupervision',
  'Qualtrics',
  'Research Professional',
  'Researcher financial dashboard ',
  'Bridges',
  'Group admin',
  'Salesforce',
  'Blackboard Transact',
  'Coupa',
  'Concur',
  'mPass',
  'Mids',
  'Workplace',
  'Finapps',
  'IIT',
  'Courseloop',
  'Peerview',
  'Shop',
  'My monash sport',
  'Global Careers',
  'Career directory',
];
// const categories = ["Course & enrolment", "Campus life & transport", "Learning & research", "Fees & scholarships", "Career & opportunities", "Help & support"]
const tags = [
  'study',
  'faculty',
  'course',
  'unit',
  'class',
  'progression map',
  'timetable',
  'map',
  'graduation',
  'leave',
  'plan',
  'schedule',
  'pause',
  'drop',
  'stop',
  'area of study',
  'grade',
  'credit',
  'results',
  'academic record',
  'transcript',
  'enrolment',
  'scholarship',
  'prior learning',
  'admission',
  'email',
  'letter',
  'download',
  'SMS',
  'communication',
  'comms',
  'virtual assistant',
  'chatbot',
  'admin',
  'form',
  'survey',
  'policy',
  'research',
  'ELN',
  'electronic notebook',
  'lab',
  'data',
  'laboratory',
  'database',
  'cost',
  'directory',
  'repository',
  'analytics',
  'report',
  'government',
  'centrelink',
  'Commonwealth',
  'fees',
  'purchase',
  'change course',
  'transfer',
  'identity',
  'profile',
  'contact',
  'transport',
  'domestic',
  'international',
  'health',
  'computer',
  'laptop',
  'tools',
  'career',
  'job',
  'full time',
  'part time',
  'casual',
  'internship',
  'IBL',
  'HR',
  'recruitment',
  'onboarding',
  'induction',
  'staff',
  'employee',
  'application',
  'superannuation',
  'benefits',
  'assessment',
  'exam',
  'deferred',
  'dealine',
  'preference',
  'preferred',
  'postgraduate',
  'undergraduate',
  'graduate',
  'help',
  'support',
  'security',
  'dates',
  'holidays',
  'm-pass',
  'ID',
  'transaction',
  'BMC',
  'service request',
  'expense',
  'esolutions',
  'esol',
  'system',
  'Log',
  'ticketing',
  'procurement',
  'claim',
  'finance',
  'gmail',
  'drive',
  'doc',
  'slide',
  'hangout',
  'calendar',
  'room',
  'venue',
];

const LinkForm = ({ existing, deleteLink, submit, cancel }) => {
  const { halfWidth } = useContext(SlideOutContext);
  const blank = useMemo(() => {
    return {
      name: '',
      type: 'Feature',
      app: '',
      description: '',
      longDescription: '',
      userGroups: [],
      categories: [],
      url: '',
      tags: [],
      disabled: false,
    };
  }, []);

  const [data, setData] = useState(
    // existing ?
    // {...existing, userGroups: existing.accessControlList.whiteList.allowGroups}
    // : blank
    existing || blank
  );

  useEffect(() => {
    setData(
      // existing ?
      // {...existing, userGroups: existing.accessControlList.whiteList.allowGroups}
      // : blank
      existing || blank
    );
  }, [blank, existing]);

  const [loading, setLoading] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);

  const validate = useCallback(() => {
    const invalid = [];

    if (!data.name) invalid.push('Name');
    if (!data.type) invalid.push('Type');
    if (!data.app && data.type === 'Feature') invalid.push('App');
    if (!data.description) invalid.push('Description');
    if (!data.categories?.length) invalid.push('Categories');
    if (!data.url) invalid.push('URL');

    setInvalidFields(invalid);
  }, [data]);

  useEffect(() => {
    validate();
  }, [data, validate]);

  const updateData = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const handleSubmit = () => {
    if (invalidFields.length) return false;

    if (data.type === 'Application') {
      data.app = data.name;
    }
    if (data.userGroups) {
      data.accessControlList = {
        allowGroups: data.userGroups,
        allowUsers: [],
      };
      delete data.userGroups;
    }

    submit(data).then((r) => {
      setLoading(false);
    });

    setLoading(true);
  };

  const dataChanged =
    !data.id || !existing
      ? false // started as blank
      : Object.keys(data)
          .filter((k) => !['keywords'].includes(k))
          .some((field) => data[field] !== existing[field]);

  // form props
  const formHeaderActions = [
    {
      icon: Icon.Trash,
      onClick: deleteLink,
      disabled: !existing,
      label: 'Delete link',
    },
  ];

  return (
    <SlideOutForm
      width={halfWidth}
      heading={data?.name}
      headerActions={formHeaderActions}
    >
      <div className={c.form}>
        {/* Name */}
        <div>
          <label htmlFor="name">Name</label>
          <TextField
            id="name"
            placeholder="Untitled"
            value={data.name}
            onChange={(e) => updateData('name', e.target.value)}
          />
          <label htmlFor="name">
            *Could be application name (WES) or a feature (Request a letter)
          </label>
        </div>

        {/* Type */}
        <div>
          <label htmlFor="type">Type</label>
          {/* <Dropdown 
            id="type"
            data={["Application", "Feature"]}
            selectedValue={data.type}
            onChange={(e) => updateData('type', e)}
            // disabled
          /> */}
          <DropdownWithValue
            id="type"
            ariaLabelledby="type"
            onChange={(e) => updateData('type', e)}
            value={data.type}
            data={['Application', 'Feature'].map((x, i) => {
              return { id: i, text: x, value: x };
            })}
          >
            {/* {["Application", "Feature"].map(o => <DropdownItem value={o}>{o}</DropdownItem>)} */}
          </DropdownWithValue>
        </div>

        {/* App */}
        {data.type === 'Feature' && (
          <div>
            <label htmlFor="app">App</label>
            {/* <Dropdown 
            id="app"
            data={apps}
            selectedValue={data.app}
            onChange={(e) => updateData('app', e)}
          /> */}
            <DropdownWithValue
              id="app"
              ariaLabelledby="app"
              onChange={(e) => updateData('app', e)}
              value={data.app}
              data={apps.map((x, i) => {
                return { id: i, text: x, value: x };
              })}
            >
              {/* {apps.map(o => <DropdownItem value={o}>{o}</DropdownItem>)} */}
            </DropdownWithValue>
          </div>
        )}

        {/* Description */}
        <div>
          <label htmlFor="desc">Description</label>
          <TextField
            id="desc"
            placeholder="Brief description of the link"
            value={data.description}
            onChange={(e) => updateData('description', e.target.value)}
            multiline
          />
        </div>

        {/* Long Description */}
        <div>
          <label htmlFor="long-desc">Long Description</label>
          <TextField
            id="long-desc"
            placeholder="Big description of the link"
            value={data.longDescription}
            onChange={(e) => updateData('longDescription', e.target.value)}
            multiline
          />
        </div>

        {/* Categories */}
        <div>
          <label htmlFor="categories">Categories</label>
          <MultiSelect
            id="categories"
            label="categories"
            data={categories}
            selected={data.categories}
            setSelected={(e) => updateData('categories', e)}
          />
        </div>

        {/* URL */}
        <div>
          <label htmlFor="url">URL</label>
          <TextField
            id="url"
            placeholder="https://"
            value={data.url}
            onChange={(e) => updateData('url', e.target.value)}
          />
        </div>

        {/* Tags */}
        <div>
          <label htmlFor="tags">Tags</label>
          <MultiSelect
            id="tags"
            label="tags"
            data={tags}
            selected={data.tags}
            setSelected={(e) => updateData('tags', e)}
            strict={false}
          />
        </div>
      </div>
      <div className={c.footer}>
        {!!invalidFields.length && (
          <div className={c.invalid}>
            Please complete: <span>{invalidFields.join(', ')}</span>
          </div>
        )}
        <div className={c.buttons}>
          {dataChanged && (
            <Button type="text" size="medium" onClick={cancel}>
              Cancel
            </Button>
          )}
          <Button
            type="primary"
            size="medium"
            onClick={handleSubmit}
            disabled={(existing && !dataChanged) || invalidFields.length}
            loading={loading}
          >
            {data.id ? 'Update' : 'Save'}
          </Button>
        </div>
      </div>
    </SlideOutForm>
  );
};

export default LinkForm;
