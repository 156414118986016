import {
  APIContext,
  getIcon,
  portalLink,
} from '@monash/portal-frontend-common';
import React, { useEffect, useState, useContext } from 'react';
import UserCard from './user-card/UserCard';
import UserSearch from './UserSearch';
import c from './impersonation.module.scss';
import RecentSearches from './recent-searches/RecentSearches';
import useLocalStorage from '../../../hooks/use-local-storage';
import { ExternalLink, LoadingIndicatorPlanet } from '@monash/portal-react';
import { IdSearch } from './IdSearch';

const Users = () => {
  const [recentSearches, setRecentSearches] = useLocalStorage(
    'recentSearches',
    []
  );

  const { isImpersonating } = useContext(APIContext);

  const [currentlyImpersonating, setCurrentlyImpersonating] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    isImpersonating().then((response) => {
      const { preferredName, lastName, studentId } = response;
      const details = {
        studentId,
        name: `${preferredName} ${lastName}`,
      };

      setCurrentlyImpersonating(
        response.currentlyImpersonating ? details : null
      );
      setLoading(false);
    });
  }, [isImpersonating]);

  const startImpersonation = (user) => {
    setCurrentlyImpersonating(user);
  };

  const AppLink = ({ icon, name, url }) => (
    <div className={c.link}>
      <div className={c.appName}>
        <span>{icon}</span>
        <span>{name}</span>
      </div>
      <ExternalLink text="Visit" link={url} mode="card" variant="text" />
    </div>
  );

  if (loading) {
    return (
      <div className={c.container}>
        <LoadingIndicatorPlanet />
      </div>
    );
  }

  return (
    <div className={c.container}>
      <div>
        <h1 className={c.title}>Users</h1>

        <div className={c.impersonationWrapper}>
          {currentlyImpersonating && (
            <>
              <div className={c.searchContainer}>
                <h2>Currently Impersonating</h2>
                <UserCard
                  data={currentlyImpersonating}
                  onEnd={() => setCurrentlyImpersonating(false)}
                  active
                />
              </div>

              <div className={c.applications}>
                <h2>Available applications</h2>
                <div className={c.links}>
                  <AppLink
                    name="Portal Home"
                    url={portalLink('home')}
                    icon={getIcon('Home')}
                  />
                  <AppLink
                    name="Monash Links"
                    url={portalLink('links')}
                    icon={getIcon('Links')}
                  />
                  <AppLink
                    name="Profile"
                    url={portalLink('profile')}
                    icon={getIcon('Profile')}
                  />
                </div>
              </div>
            </>
          )}

          {!currentlyImpersonating && (
            <>
              <UserSearch
                onSelect={startImpersonation}
                recentSearches={recentSearches}
                setRecentSearches={setRecentSearches}
              />
              {Boolean(recentSearches.length) && (
                <RecentSearches
                  recentSearches={recentSearches.slice(0, 10)}
                  onSelect={startImpersonation}
                />
              )}
            </>
          )}
        </div>

        <IdSearch />
      </div>
    </div>
  );
};

export default Users;
