import React from 'react';
import c from './notification-apps.module.scss';
import { useFormContext } from 'react-hook-form';
import NotificationApp from './notification-app/NotificationApp';
import { NOTIFICATION_APPS } from '../../../../../constants/notifications-props';
import InlineErrorMessage from '../../../../common/inline-error-message/InlineErrorMessage';

const NotificationApps = ({ disabled, allUserGroups }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const apps = watch('apps');

  // custom register
  register('apps', {
    validate: (value) =>
      (Array.isArray(value) && value.length !== 0) ||
      'An application must be selected',
  });

  return (
    <div className={c.notificationApps}>
      {Object.values(NOTIFICATION_APPS).map((app) => (
        <NotificationApp
          key={app.id}
          app={app}
          allUserGroups={allUserGroups?.[app.id]}
          availableUserGroups={allUserGroups?.[app.id].filter(
            (group) => group.available
          )}
          apps={apps}
          disabled={disabled}
        />
      ))}
      {errors?.apps && <InlineErrorMessage message={errors.apps?.message} />}
    </div>
  );
};

export default NotificationApps;
